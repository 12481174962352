@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: black;
}

/* 
    TYPOGRAPHY
*/

@font-face {
    font-family: 'css-font';
    src: url('./assets/CSS-Font.otf') format('opentype');
    font-display: block;
  }

h1, h2, h3, h4, a, h1 a, p {
    font-family: 'Ubuntu', sans-serif;
}

h1, h1 a {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
    line-height: 1.3;
}

h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
}

h3, h4 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.3;
}

p {
    font-size: 1.4rem;
    font-weight: 500; 
    line-height: 1.5;
}

a {
    font-size: 1rem;
    line-height: 1.3;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
}

h1 a.active {
    text-decoration: underline;
}

a.active{
    text-decoration: none;
}

a:hover {
    filter: blur(5px);
}

/* 
    LAYOUT 
*/

body {
    background: linear-gradient(90DEG, #5bcdfa77, #F5A9B877);
}

main {
    padding: 90px 5.2vw 120px;
    min-height: calc(100vh - 180px);
}

section {
    margin: auto;
    max-width: 1400px;
}

.large-spacer {
    margin-top: 120px;
}

.medium-spacer {
    margin-top: 80px;
}

.small-spacer {
    margin-top: 60px;
}

.x-small-spacer {
    margin: 40px 0;
}

/* 
    HEADER
*/

.header {
    z-index: 1;
    background-color: white;
    box-shadow: 0px 1px 10px #00000080;
    position: fixed;
    top: 0;
    height: 90px;
    width: 100%;
    padding: 35px 5.2vw 35px 5.2vw;
}

.header div {
    margin: auto;
    max-width: 1400px;
    display: grid;
    grid-template-areas: 'logo nav';
}

.logo {
    width: 20px;
    font-weight: 700;
}

.nav {
    justify-content: end;
    grid-area: nav;
    display: grid;
    grid-template-columns: repeat(2, 145px);
    align-items: center;
    justify-items: end;
}

.menu-button {
    font-size: 1.25rem;
    font-weight: 700;
    display: none;
    outline: none;
    width: 30px;
    height: 20px;
    border: none;
    background-color: transparent;
    justify-self: end;
    letter-spacing: 2px;
}

.menu-button:hover {
    cursor: pointer;
    filter: blur(5px);
}


/*
    FOOTER
*/

footer {
    width: 100%;
    height: 180px;
    background-color: white;
    padding: 75px 5.2vw 75px;
}

#footer--container {
    max-width: 1400px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

#footer--container p {
    font-size: 1rem;
    justify-self: center;
}

#footer--container div {
    justify-self: end;
}

footer img {
    width: 40px;
}

#footer--logos {
    display: flex;
    flex-flow: row nowrap;
    gap: 105px;
}

/* 
    HOME PAGE
*/

.letters {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
}

.letters span {
    font-family: css-font;
    display: block;
    font-size: 82px;
    padding: 4px 4px;
    line-height: 1;
    transition: filter 2s;
}

.letters span:hover {
    cursor: default;
    filter: blur(5px);
    transition: filter 0.1s;
}

/* 
    WORK
*/

.work-grid-title {
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-auto-rows: auto;
    align-items: center;
    gap: 20px;
    grid-template-areas:
    "name line link";
}

.work-grid-title h2 {
    font-size: 1.5rem;
    grid-area: name;
    white-space: nowrap;
}

.line {
    grid-area: line;
    border: solid black 3px;
    border-radius: 20px;
    background-color: #ffffff4e;
    height: 15px;
    width: 100%;
}

.work-grid-title a{
    grid-area: link;
    font-size: 1.4rem;
    font-weight: 700;
    justify-self: end;
}

.work-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
}

.work-section img,
.work-section iframe {
    aspect-ratio: 16 / 10;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 15px #00000033;
}

iframe {
    background-color: black;
    cursor: pointer;
}

/* 
    ABOUT PAGE
*/

.about-photo {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 15px #00000033;
}

.about-section-grid{
    display: grid;
    grid-template-columns: 1fr 466px;
}

.about-education {
    padding-right: 0px 30px 0px 5px;
    justify-self: end;
}

.about-education-grid {
    line-height: 2rem;
    margin-top: 30px;
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

/* 
    MEDIA QUERIES 
*/

@media only screen and (max-width: 1414px) { 

    .work-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1000px) { 


    /* typography */

    h1, h2, h3{
        text-align: center;
    }

    main {
        min-height: calc(100vh - 290px);
    }

    .work-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .header div {
        grid-template-areas: 'logo button' 'nav nav';
    }

    .nav {
        margin-top: 60px;
        grid-template-rows: repeat(3, 20px);
        grid-template-columns: none;
        grid-row-gap: 40px;
    }

    .nav a{
        box-shadow: 0px 0px 5px #00000080;
        border: solid 2px black;
        text-decoration: none;
        background-color: #ffffffe1;
        width: 110px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        border-radius: 10px;
    }

    .menu-button{
        grid-area: button;
        display: block;
    }

    footer {
        height: 290px;
    }

    #footer--container {
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        gap: 10px;
    }
    
    #footer--container div {
        justify-self: center;
    }
    
    footer img {
        width: 50px;
    }
    
    #footer--logos {
        margin-top: 25px;
        gap: 30px;
    }

    /* gets rid of letter blur */

    .letters span {
        transition: none;
    }
    
    .letters span:hover {
        filter: blur(0px);
        transition: none;
    }

    .about-section-grid{
        display: block;
    }

    .about-education {
        width: 100%;
        padding: 40px 0 0;
        justify-self: start;
    }

    .about-education-grid {
        text-align: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        gap: 40px;
    }

    .about-education-grid div {
        background-color: #ffffff4e;
        border: solid black 2px;
        box-shadow: 0px 0px 10px #00000033;
        width: 100%;
        padding: 25px 10px;
        border-radius: 20px;
    }

}

@media only screen and (max-width: 700px) {

    /* layout */

    p {
        font-size: 1.3rem;
    }

    .large-spacer {
        margin-top: 60px;
    }

    .medium-spacer {
        margin-top: 40px;
    }

    .small-spacer {
        margin-top: 30px;
    }

    /* makes home title responsive */

    .letters span {
        max-width: 140px;
        width: 100%;
        min-width: 0px;
        font-size: 12.5vw;
    }

    .letters {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        grid-template-columns: repeat(4, 1fr);
    }

    /* makes work title display block */

    .work-grid-title {
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
    }

    .work-grid-title h2,
    .work-grid-title a {
        white-space: normal;
        text-align: center;
    }

    .work-grid-title a {
        font-size: 1rem;
        width: 100%;
        padding: 5px 0;
        border-radius: 10px;
        border: solid 2px black;
        text-decoration: none;
        background-color: #ffffff4e;
    }

    .line {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    /* turns off link hover blur */

    a:hover {
        filter: blur(0px);
    }
    .menu-button:hover {
        filter: blur(0px);
    }
}

